import React, { useEffect, useState } from "react";
import { useAuth } from "./../../contexts/authContext";

import axios from "axios";
import firebase from "firebase/app";
import "firebase/firestore";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./User.scss";

export default function User({ handleClose }) {
  const { currentUser } = useAuth();
  const { setCurrentUser } = useAuth();
  const [id, setID] = React.useState();
  const [showSubBtn, setShowSubBtn] = React.useState(false);
  const [plan, setPlan] = React.useState("Please Wait");
  const [subDate, setSubDate] = React.useState("Please Wait");
  const [filesUnderP, setFileUnderP] = React.useState(0);
  const [uploads, setUploads] = React.useState(0);

  useEffect(() => {
    const handleStripeApi = async () => {
      if (id !== "") {
        const res = await axios.post("http://localhost:3000/subId", {
          id: id,
        });
        if (res.data.Data.object === "subscription") {
          const expire_data = res.data.Data.current_period_end;
          const millisec = expire_data * 1000;
          const date = new Date(millisec);
          setSubDate(date.toLocaleString());
        }
      }
    };

    function handleShowData() {
      var db = firebase.firestore();
      let docRef = db.collection("users").doc(localStorage.getItem("token"));
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setID(doc.data().subscription_id);
            if (
              doc.data().trial === "no" &&
              doc.data().subscription_id !== ""
            ) {
              setPlan("Monthly Plan");
              setShowSubBtn(true);
              handleStripeApi();
            } else if (doc.data().trial === "yes") {
              var date = doc.data().trialDate + 86400000 * 14;
              date = new Date(date);
              setSubDate(date.toLocaleString());
              setPlan("Trial");
              setShowSubBtn(false);
            } else {
              setPlan("Free Account");
              setSubDate("Not yet Started");
              setShowSubBtn(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    handleShowData();
  }, [id]);

  async function handleSubscriptionDel() {
    var db = firebase.firestore();
    try {
      const res = await axios.post("http://localhost:3000/del", {
        id: id,
      });
      console.log("sss");
      let docRef = db.collection("users").doc(localStorage.getItem("token"));
      docRef
        .update({
          subscription_id: "",
          trial: "",
        })
        .then(() => {
          setID("");
          setShowSubBtn(false);
        });
      setPlan("Free Account");
      setSubDate("Not yet Started");
    } catch (ex) {
      console.log(ex);
    }
    toast.info("Subscription Ends Successfully", {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: true,
      autoClose: 2000,
    });
  }

  function handleUserSignOut() {
    setCurrentUser("");
    localStorage.removeItem("token");
    handleClose();
    window.location.reload();
  }

  return (
    <div className="UserDataHolder">
      <ToastContainer />
      <div className="lineAroundUserData">
        <div className="userIconInfo">
          <img className="userIcon" src="./accountcircle24px.svg" />
          <div className="userEmail">{localStorage.getItem("token")}</div>
        </div>
        <div className="subHolder">
          <div className="subInfo">
            <div>Subscription Type:</div>
            <div className="subInfoDetail">{plan}</div>
          </div>
          <div className="subInfo">
            <div>Subscription Ends on:</div>
            <div className="subInfoDetail">{subDate}</div>
          </div>
          <div className="subInfo">
            <div>Files Uploaded:</div>
            <div className="subInfoDetail">{uploads}</div>
          </div>
          <div className="subInfo">
            <div>Files under Processing:</div>
            <div className="subInfoDetail">{filesUnderP}</div>
          </div>
        </div>
        <div className="btnHodler">
          <div onClick={handleUserSignOut} className="signoutBtn">
            Sign Out
          </div>
          {showSubBtn && (
            <div
              onClick={handleSubscriptionDel}
              className="signoutBtn subEndBtn"
            >
              End Subscription
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
