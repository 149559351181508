import firebase from "firebase/app";
import "firebase/auth";

/*const app = firebase.initializeApp({
  apiKey: "AIzaSyBn1-lzmyOlJJEK4Fru8l4KPr2elE2XkbU",
  authDomain: "acclaimed-labs.firebaseapp.com",
  projectId: "acclaimed-labs",
  storageBucket: "acclaimed-labs.appspot.com",
  messagingSenderId: "28764268502",
  appId: "1:28764268502:web:f9419826e8544b4dd96677",
  measurementId: "G-XR1064K1ZJ",
});*/

const app = firebase.initializeApp({
  apiKey: "AIzaSyCIAo_4i7DiUhfUu-v2Yq3U0KDA-hPtM5I",
  authDomain: "acclaimed-labs-live.firebaseapp.com",
  projectId: "acclaimed-labs-live",
  storageBucket: "acclaimed-labs-live.appspot.com",
  messagingSenderId: "55963150691",
  appId: "1:55963150691:web:b87820f81aab501be28b56",
  measurementId: "G-FJEV47RVRZ",
});

/*const app = firebase.initializeApp({
  apiKey: "AIzaSyAs-ovQW2G-I1V-IVdupJWZCerhyoZDafo",
  authDomain: "testacclaimedlabs.firebaseapp.com",
  projectId: "testacclaimedlabs",
  storageBucket: "testacclaimedlabs.appspot.com",
  messagingSenderId: "888601138377",
  appId: "1:888601138377:web:c103039466dc654e126918",
  measurementId: "G-T3N3QGCK63",
});*/

export const auth = app.auth();
export default app;
