import React from "react";
import { Link } from "react-router-dom";

import "./footer.scss";

export default function footer() {
  return (
    <div className="footerHolder">
      <div>
        <div className="footer">
          <div className="footerLogo">
            <img src="logoWhite.svg" />
          </div>
          <div className="footerLineOne"></div>
          <div className="footerSupport">
            <div>support</div>
            <div>
              <Link to="/contact">contact</Link>
            </div>
          </div>
          <div className="footerLineOne"></div>
          <div className="footerLinks">
            <div>Links</div>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <Link to="/upload">upload</Link>
            </div>
            <div>
              <Link to="/about">About</Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="copyright">&#169; Acclaimed Labs - 2021.</div>
      </div>
    </div>
  );
}
