import React from "react";
import { Link } from "react-router-dom";
import { NavBar } from "../../components/navBar/navBar";
import "./api.scss";
import Footer from "./../../components/footer/footer";

export default function Api(props) {
  return (
    <div>
      <NavBar active={props.match.path} />
      <div className="api-intro">Acclaimed Labs API -Forecasting at scale.</div>
      <div className="api-intro-line"></div>
      <div className="api-sec-one">
        Hello and welcome. Acclaimed Labs offers our professional REST API access endpoint to banks, hedge funds and other "forward-looking" organizations that require cloud based technology in order to better and faster predict timeseries data.
		<div>&nbsp;</div>
		 Our Statisticians and Data Scientists used R and Python to construct our powerful univariate data forecasting solution. This makes our solution highly scalable. 
		<div>&nbsp;</div>
		Our API allows your organization to directly upload large datasets and recieve predictions in large scale json or a forecast with historical charts and model performance in a .pdf.   
      </div>
      <div className="api-sec-two">
        The API consists of several resources, and supports standard HTTP
        operations <span className="bold">(POST, GET, PUT, PATCH, DELETE)</span>{" "}
        to accomplish these tasks.
      </div>
      <div className="api-sec-three">
        <div className="bold" style={{ marginBottom: "10px" }}>
          Primary API uses for our API:
        </div>
        <div className="explain">
          1. modeling and forecasting large amounts of timeseries financial and kpi data.
        </div>
        <div className="explain">
          2. building and delivering apps based on Acclaimed Labs forecasting
          technology.
        </div>
        <div className="explain">3. Bring your own use. Banks, Hedge Funds, Payments Companies, Insurance, Government, Transportation, Fuel, Health Care, Retail, manufaturing, etc. </div>
      </div>
      <div className="api-sec-four">
        CLICK <Link to="http://www.acclaimedlabs.com/api/docs">HERE</Link> FOR
        API DOCUMENTATION
      </div>
      <div className="api-intro" style={{ marginTop: "100px" }}>
        Miscellaneous Representations
      </div>
      <div className="api-intro-line"></div>
      <div className="api-sec-two">
        For all methods which accept or return structured data, either{" "}
        <span className="bold">XML</span> or <span className="bold">JSON</span>{" "}
        may be used.
      </div>
      <div className="api-sec-one">
        For data returned from the API (e.g. for retrieving forecast details), you
        can specify whether you would like JSON, XML or .PDF using either the Accept
        HTTP Header, or by appending ".json" or ".xml" or ".pdf" to the end of the URL.
        The default, if neither of those is used, is to return JSON.
      </div>
      <div className="api-sec-one">
        For data sent to the API (e.g. for uploading timeseries data to
        forecast), you can specify whether you are using JSON or XML using the
        Content-Type HTTP header. By default, if no Content-Type header is
        provided, the API assumes the input is JSON. 
      </div>
      <div className="api-sec-one">We can accept custom scripting.</div>
      <div className="api-intro" style={{ marginTop: "100px" }}>
        URLs
      </div>
      <div className="api-intro-line"></div>
      <div className="api-sec-one">
        The URLs for the various resources use the following pattern:
      </div>
      <div className="url">
        {"https://{base url}/api/v{version}/{operation-specific component}"}
      </div>

      <div className="api-sec-three">
        <div className="bold" style={{ marginBottom: "10px" }}>
          The components of the URL are:
        </div>
      </div>
      <div className="components-sec">
        <div className="components">
          <div
            className="bold"
            style={{
              fontSize: "20px",
              marginBottom: "15px",
            }}
          >
            base url
          </div>
          <div>
            this is{" "}
            <span className="bold">www.acclaimedlabs.com/api /test</span> for
            the production environment and{" "}
            <span className="bold">api.acclaimedlabs.com</span> for the live
            version
          </div>
        </div>
        <div className="components">
          <div
            className="bold"
            style={{
              fontSize: "20px",
              marginBottom: "15px",
            }}
          >
            version
          </div>
          <div>
            the API{" "}
            <span className="bold">
              https://www.acclaimedlabs. com/api/version1
            </span>{" "}
            Currently there is only one version, "1", in operation
          </div>
        </div>
        <div className="components">
          <div
            className="bold"
            style={{
              fontSize: "20px",
              marginBottom: "15px",
            }}
          >
            operation-specific component
          </div>
          <div>
            this identifies the actual operation you want to do, eg /post for
            uploading timeseries data to forecast
          </div>
        </div>
      </div>
      <div className="api-sec-one">
        Example: The URL for retrieving a list of the forecasts for the authenticated user in
        the production environment is https://www.acclaimedlabs.com/api/v1/get
      </div>
      <div className="api-intro" style={{ marginTop: "100px" }}>
        API KEY
      </div>
      <div className="api-intro-line"></div>
      <div className="api-sec-one">
        Once you are ready to call to the API for testing, please email contact@acclaimedlabs.com to generate a
        14 day free trial API key with the same account you set up your free trial account with.
       
      </div>
      <div className="api-sec-one">
        If you are an enterprise client who wants to integrate, build or monetize the acclaimed labs api, please email contact@acclaimedlabs.com
      </div>
      <Footer />
    </div>
  );
}
