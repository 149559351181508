import React, { useState, useEffect } from "react";
import { NavBar } from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";

import "./contact.scss";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginLeft: "auto",
    marginRight: "auto",
    flexWrap: "wrap",
  },
  formTwo: {
    width: "85%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "Column",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginLeft: "auto",
    marginRight: "auto",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    width: "80%",
  },
  button: {
    marginTop: theme.spacing(4),
    width: "80%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Contact(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    var mailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      fName != "" &&
      lName != "" &&
      email.match(mailFormat) &&
      reason != "" &&
      massage != ""
    ) {
      setSubmitBtn(false);
    }
    if (
      massage.length == 0 ||
      fName.length == 0 ||
      !email.match(mailFormat) ||
      lName.length == 0 ||
      reason.length == 0
    )
      setSubmitBtn(true);
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  const handleOpenSubmit = () => {
    setOpenSubmit(true);
  };

  function handleSubmit() {
    handleOpenSubmit();
    setFName("");
    setLName("");
    setEmail("");
    setReason("");
    setMassage("");
  }
  const classes = useStyles();
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [submitBtn, setSubmitBtn] = useState(true);
  const [massage, setMassage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openSubmit, setOpenSubmit] = React.useState(false);

  return (
    <div>
      <NavBar active={props.match.path} />
      <div className="contactTitle">
        We value your interest in Acclaimed Labs. We will contact you asap.
      </div>
      <form className={classes.form} noValidate>
        <TextField
          value={fName}
          onChange={(e) => setFName(e.currentTarget.value)}
          id="standard-basic"
          label="First Name"
        />

        <TextField
          value={lName}
          onChange={(e) => setLName(e.currentTarget.value)}
          id="standard-basic"
          label="Last Name"
        />

        <TextField
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          id="standard-basic"
          label="Email"
        />
        <FormControl className={classes.formControl} noValidate>
          <InputLabel id="demo-controlled-open-select-label">Reason</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          >
            <MenuItem value="enterprise">Enterprise</MenuItem>
            <MenuItem value="cs">Customer Support</MenuItem>
            <MenuItem value="Sales inquiry">Sales Inquiry</MenuItem>
            <MenuItem value="Business development">
              Business Development
            </MenuItem>
          </Select>
        </FormControl>
      </form>
      <form className={classes.formTwo} noValidate>
        <TextField
          value={massage}
          onChange={(e) => setMassage(e.currentTarget.value)}
          className={classes.textField}
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={8}
          variant="outlined"
          labelWidth={60}
        />
        <Button
          onClick={handleSubmit}
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={submitBtn}
        >
          Submit
        </Button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openSubmit}
          onClose={handleCloseSubmit}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openSubmit}>
            <div className={"modalWindow " + classes.paper}>
              Thank you for Contacting us, we will be in Contact ASAP
            </div>
          </Fade>
        </Modal>
      </form>
      <Footer />
    </div>
  );
}
