import { Route, Switch, Redirect } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import upload from "./pages/upload/upload";
import contact from "./pages/contact/contact";
import pricing from "./pages/pricing/pricing";
import UserFiles from "./pages/userFiles/userFiles";
import home from "./pages/home/home";
import Checkout from "./components/Checkout/Checkout";
import about from "./pages/about/about";
import api from "./pages/api/api";
import notFound from "./pages/notFound";
import AuthProvider from "./contexts/authContext";
import "./App.scss";
import Technology from "./pages/technology/technology";

const stripePromise = loadStripe(
  "sk_test_51ICon1JyJhbl1U8COXqLov0rrFHA9ud6RcViLmFg3QEu2LFB3aSjA9XuwYLmtMRSHatPnS7wb5b6EP9MGgxp1D6k00GRAUGQYa"
);

function App(props) {
  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <div>
          <Switch>
            <Route path="/upload" component={upload} />
            <Route path="/contact" component={contact} />
            <Route path="/pricing" component={pricing} />
            <Route path="/about" component={about} />
            <Route path="/technology" component={Technology} />
            <Route path="/api" component={api} />
            <Route
              path="/checkout"
              render={(props) => {
                if (!localStorage.getItem("token")) return <Redirect to="/" />;
                return <Checkout {...props} />;
              }}
            />
            <Route path="/User-files/:email/:password" component={UserFiles} />
            <Route path="/not-found" exact component={notFound} />
            <Route path="/" exact component={home} />
            <Redirect to="/not-found" />
          </Switch>
        </div>
      </AuthProvider>
    </Elements>
  );
}

export default App;
