import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import Joi from "joi-browser";
import "firebase/auth";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import firebase from "firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      Acclaimed labs 2021
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(props) {
  const classes = useStyles();

  const db = firebase.firestore();
  const [account, setAccount] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const { signup } = useAuth();
  const [loading, setLoading] = useState(false);
  const schema = {
    email: Joi.string().email().required().label("Email"),
    password: Joi.string().min(6).required().label("Password"),
  };

  function validate() {
    const result = Joi.validate(account, schema, { abortEarly: false });

    if (!result.error) return { email: "", password: "" };

    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const errors = validate();
    setErrors(errors);
    if (errors.email !== "" && errors.password !== "") return;
    setLoading(true);
    try {
      const response = await signup(account.email, account.password);
      if (response.additionalUserInfo.isNewUser === true) {
        localStorage.setItem("token", response.user.email);
        toast.success("Account Created Successfully", {
          position: toast.POSITION.TOP_CENTER,
          closeOnClick: true,
          autoClose: 2000,
        });

        function firebaseUser() {
          db.collection("users")
            .doc(response.user.email)
            .set({
              subscription_id: "",
              trial: "",
              trialDate: "",
              trialCompleted: "",
            })
            .then(function () {
              console.log("Value successfully written!");
            })
            .catch(function (error) {
              console.error("Error writing Value: ", error);
            });
        }
        firebaseUser();

        function redirectToHome() {
          props.handleCloseSignup();
        }
        setTimeout(redirectToHome, 2000);
        setLoading(false);
        setAccount({ email: "", password: "" });
      }
    } catch (ex) {
      toast.info(ex.message, {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
        autoClose: 2000,
      });
      setLoading(false);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <ToastContainer />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {errors && (
          <Grid item xs={12} sm={6}>
            <div>{errors.signup}</div>
          </Grid>
        )}
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={errors.email ? true : false}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) =>
                  setAccount({
                    email: e.currentTarget.value,
                    password: account.password,
                  })
                }
                value={account.email}
              />
              {errors && <div style={{ marginTop: "5px" }}>{errors.email}</div>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.password ? true : false}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) =>
                  setAccount({
                    email: account.email,
                    password: e.currentTarget.value,
                  })
                }
                value={account.password}
              />
              {errors && (
                <div style={{ marginTop: "5px" }}>{errors.password}</div>
              )}
            </Grid>
          </Grid>
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link
                onClick={props.handleShowLoginForm}
                style={{ textDecoration: "none" }}
                to="#"
                variant="body2"
              >
                Already have an account? Login
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5} pb={1}>
        <Copyright />
      </Box>
    </Container>
  );
}
