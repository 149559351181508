import React, { useState, useEffect } from "react";

import "./userFiles.scss";

import { makeStyles } from "@material-ui/core/styles";

import { useAuth } from "../../contexts/authContext";

import firebase from "firebase/app";
import "firebase/storage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "40ch",
    },
  },
  btn: {
    marginTop: "20px",
    width: "44ch",
    height: "7vh",
  },
}));

export default function UserFiles(props) {
  const [users, setUsers] = useState([]);
  const [links, setLinks] = useState([]);
  const [disable, setDisable] = useState(false);
  const classes = useStyles();

  const { login } = useAuth();

  useEffect(async () => {
    setDisable(true);
    var fileArr = [];
    var usersArr = [];
    const jwt = await login(
      props.match.params.email,
      props.match.params.password
    );
    if (jwt.user.email === "real-admin777@gmail.com") {
      var storage = firebase.storage().ref();
      var usersRef = storage.child("user_uploads");
      usersRef.listAll().then((res) => {
        res._delegate.prefixes.forEach((userRef) => {
          usersArr = [
            ...usersArr,
            {
              userPath: userRef._location.path_,
            },
          ];
        });
        setUsers(usersArr);
        usersArr.map((user) => {
          var fileRef = storage.child(user.userPath);
          fileRef
            .listAll()
            .then((res) => {
              res.items.forEach((itemRef) => {
                let file = itemRef._delegate._location.path_;
                storage
                  .child(file)
                  .getDownloadURL()
                  .then((url) => {
                    fileArr = [
                      ...fileArr,
                      {
                        id: fileArr.length,
                        email: user.userPath,
                        value: url,
                      },
                    ];
                    setLinks(fileArr);
                  });
              });
            })
            .catch((error) => {});
        });
        setDisable(false);
      });
    }
  }, []);

  function handleCopy() {
    let div = document.getElementById("copy");
    let text = div.innerText;
    let textArea = document.createElement("textarea");
    textArea.width = "1px";
    textArea.height = "1px";
    textArea.background = "transparents";
    textArea.value = text;
    document.body.append(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  return (
    <div>
      <div className="UserFile">
        <div className="header">Acclaimed Labs User's Files Center</div>
      </div>
      {links.map((link) => (
        <div className="fileLinksHolder">
          <div className="userEmail">{link.email}</div>
          <div id="copy" className="fileLinks" key={link.id}>
            {link.value}
          </div>
        </div>
      ))}
    </div>
  );
}
