import React from "react";
import { Link } from "react-router-dom";
import { NavBar } from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";

import "./technology.scss";
export default function technology(props) {
  return (
    <div>
      <NavBar active={props.match.path} />
      <div className="titleSec">
        <div className="underline"></div>
        <div className="worldClass">World Class Forecasting Technology</div>
        <div className="underline"></div>
      </div>
      <div className="technologyIsometric">
        <div className="subText">
          <div className="technoTitle">Statistics</div>
          <div className="technoTitleBlue">meets</div>
          <div className="technoTitle">Data Science</div>
        </div>
        <img src="technoIcon.svg" />
      </div>
      <div className="technoBgSec">
        <img className="technoBg" src="technologyBringsBg.svg" />
        <div className="textText">
          Acclaimed Labs uses R and Python to auto model and publish predictions/forecasts.
        </div>
        <div className="textText">
          Our Innovative use of Data Science allowed us to deliver a fast, complete solution.
        </div>
        <div className="textText">
          We solve your predictive needs, save you money and increase your insights.
        </div>
      </div>
      <div className="techSec3">
        <div className="secHolder">
          <img src="description_black_24dp.svg" />
          <div className="secHolderPara">
            Small or Large Scale data forecasting.
          </div>
        </div>
        <div className="secHolder">
          <img src="bookmark_border_black_24dp.svg" />
          <div className="secHolderPara">
            Hedge Fund Designed Forecast Template.
          </div>
        </div>
        <div className="secHolder">
          <img src="schedule_black_24dp.svg" />
          <div className="secHolderPara">14 Day Forecast Challenge.</div>
        </div>
      </div>
      <div className="techSec4">
        <img className="techSec4bg" src="techSec4bg.svg" />
        <div className="list">
          <div className="keyFeatures">
            Predict business metrics, risk and other data
            better and faster with no bias.
          </div>
          <div className="keyFeatures">
            quickly Transform your organization with professional statistics predictions.
          </div>
          <div className="keyFeatures">
            Our predictive technology can augment your organizations strategy, adding value.
          </div>
        </div>
      </div>
      <div className="freeTrialSec">
        <img src="./freeTrialBg.png" />
        <div className="freeTrialText">
          start your free single seat trial. no credit card required. no software to install
          .
        </div>
        <Link to="/pricing" className="freeTrialBtn">
          <div>start 14-day free trial</div>
          <div>start 14-day free trial</div>
        </Link>
      </div>
      <Footer />
    </div>
  );
}
