import React, { useEffect } from "react";

import { NavBar } from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";

import "./about.scss";

export default function About(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <NavBar active={props.match.path} />
      <div className="aboutSecOneTitle">Who is Acclaimed Labs?</div>
      <div className="aboutSecOneline"></div>
      <div className="aboutSecOne">
        Acclaimed Labs is a Cloud Based SaaS (Software as a Service) Company. Using Data
        Science, we developed a fully automated statistical data modeling and
        forecast publishing solution for banks and organizations to generate predictions in high speed automation.
      </div>
      <div className="aboutSecTwo">
        <div className="aboutSecTwoCont">
          <img src="aboutQ1.svg" />
          <div className="aboutQuestion">What We Do?</div>
          <div className="infoPara">
            Our solution models data and generates professional forecasts.
          </div>
        </div>
        <div className="aboutSecTwoCont">
          <img src="aboutQ2.svg" />
          <div className="aboutQuestion">Where?</div>
          <div className="infoPara">
            In the cloud. Upload from anywhere. Your
            forecasts are quickly generated and delivered.
          </div>
        </div>
        <div className="aboutSecTwoCont">
          <img src="aboutQ3.svg" />
          <div className="aboutQuestion">Why Trust us?</div>
          <div className="infoPara">
            Transparency. Our solution publishes model
            performance and auto deletes your data. No frills. 
          </div>
        </div>
        <div className="aboutSecTwoCont">
          <img style={{ width: "90px" }} src="aboutQ4.svg" />
          <div className="aboutQuestion">Why Try us?</div>
          <div className="infoPara">
            Fast. More accurate. Unbiased. save money, reduce potential risk.
           Free trial!
          </div>
        </div>
      </div>
      <div className="note">world class engineering by statisticians</div>
      <div className="aboutSecFour">
        Using Data Science, our robust solution checks and ranks an ensemble of the finest univariate timeseries statistical models and algorithms against your timeseries data, tracking their accuracy in layered incremenets to form the finest future predictions available. 
		<div>&nbsp;</div>
		The solution applies weights where needed and often ensembles other ensembles, assuming they improve performance. Ensemble predictive solutions are the most difficult to build, and the most desired by financial and government institutions because they are more accurate than any individual model.  
      </div>
      <div className="aboutSecFive">
        <div className="aboutSecFiveOne">
          <img src="report_problem-24px.svg" />
          <div>
            Most existing data forecasting solutions focus on traditional
            regression modeling, using Fourier transformations to deal with
            multiple seasonality.
          </div>
        </div>
        <div className="aboutSecFiveTwo">
          <img src="thumb_up_alt-24px.svg" />
          <div>
            Acclaimed Labs uses data science and robust statistics in an innovative
            manner to model and forecast noisy, heavily seasonal and cyclical data.
            
          </div>
        </div>
        <div className="aboutSecFiveThree">
          <img src="accountcircle24px.svg" />
          <div>
            Acclaimed Labs’ sophisticated solution allows our clients to generate
            more accurate, shorter and longer -range forecasts.
          </div>
        </div>
        <div className="aboutSecFiveFour">
          <img src="card_giftcard-24px.svg" />
          <div>
            Exceptionally, our technology automatically publishes 
            historical predictive performance on your data, an institutional grade feature.
          </div>
        </div>
      </div>
      {/*<div className="aboutSecSix">
        We used a powerful, proprietary combination of Modern, Open Source
        Software (OSS) such as Python and R to complete our fully automated data
        forecasting platform. This gives our solution the ability to be scaled
        and customized in an unlimited capacity.
      </div>*/}
      {/*<div className="aboutSecSeven">
        <div className="aboutSecSevenLine"></div>
        <div className="aboutSecSevenTitleSec">
          <div className="aboutSecSevenTitle">T</div>
          <div className="aboutSecSevenTitle">h</div>
          <div className="aboutSecSevenTitle">i</div>
          <div className="aboutSecSevenTitle">n</div>
          <div className="aboutSecSevenTitle">k</div>
          <div className="aboutSecSevenTitle">i</div>
          <div className="aboutSecSevenTitle">n</div>
          <div className="aboutSecSevenTitle">g</div>
        </div>
        <div className="aboutSecSevenTitleSec">
          <div className="aboutSecSevenTitle">b</div>
          <div className="aboutSecSevenTitle">i</div>
          <div className="aboutSecSevenTitle">g</div>
          <div className="aboutSecSevenTitle">g</div>
          <div className="aboutSecSevenTitle">e</div>
          <div className="aboutSecSevenTitle">r</div>
        </div>
        <div>
          <div className="aboutSecSevenDetial">
            Our value added technology can :
          </div>
          <div className="aboutSecSevenFeature">generate revenue</div>
          <div className="aboutSecSevenFeature">Retain clients</div>
          <div className="aboutSecSevenFeature">build relationships</div>
        </div>
      </div> */}
      <div className="aboutSecEight">
        <div className="qoute">
          ”In today's business environment, being fast can deliver unique competitive advantages. Cloud-based, our innovative statistical modeling and forecast publishing solution allows you to forecast thousands of different timeseries data sets in complete automation.”
        </div>
        <div className="qouteOwner">
          -Judson Smith Founder/CEO Acclaimed Labs
        </div>
      </div>
      <Footer />
    </div>
  );
}
