import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { NavBar } from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";
import Login from "../login";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

import { useAuth } from "../../contexts/authContext";
import "./upload.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Upload(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openTwo, setOpenTwo] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [src, setSrc] = React.useState(null);
  const [clear, showClear] = React.useState(false);
  const [showUploadBtn, setShowUploadBtn] = React.useState(true);
  const [openLogin, setOpenLogin] = React.useState(false);

  const [showAddBtn, setShowAddBtn] = React.useState(false);

  const { currentUser } = useAuth();

  function onFileChange(event) {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    console.log("file", selectedFile);
    showClear(true);
    const fileName = document.querySelector(".fileName");
    const uploadBtn = document.querySelector("#uploadBtn");
    const embed = document.querySelector(".pdfFile");
    //cancelBtn.style.display = "block";
    if (file) {
      const reader = new FileReader();
      reader.onload = function () {
        const result = reader.result;
        setSrc(result);
        //embed.src = result;
      };
      reader.readAsDataURL(file);
    }
    uploadBtn.style.opacity = "1";
    setShowUploadBtn(false);
  }

  function onFileUpload() {
    var timestamp = Number(new Date());
    var storageRef = firebase
      .storage()
      .ref(
        `user_uploads/${localStorage.getItem("token")}/${selectedFile.name}`
      );
    storageRef.put(selectedFile);

    setSelectedFile(null);
    handleClose();
    handleOpenTwo();
    setShowUploadBtn(false);
    //const formData = new FormData();
    //formData.append("myFile", selectedFile, selectedFile.name);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUpload = () => {
    const defaultBtn = document.querySelector("#defaultBtn");
    defaultBtn.click();
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
    setSrc(false);
    setShowUploadBtn(true);
  };

  const handleOpenTwo = () => {
    setOpenTwo(true);
  };

  const handleCloseTwo = () => {
    setOpenTwo(false);
  };

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var db = firebase.firestore();
    if (localStorage.getItem("token")) {
      let docRef = db.collection("users").doc(localStorage.getItem("token"));
      docRef.get().then((doc) => {
        if (doc.exists) {
          if (doc.data().subscription_id !== "" || doc.data().trial === "yes") {
            setShowAddBtn(true);
          } else {
            setShowAddBtn(false);
          }
        }
      });
    }
  });
  return (
    <div>
      <NavBar active={props.match.path} />
      {localStorage.getItem("token") && showAddBtn && (
        <div onClick={handleOpen} className="add">
          <img src="./addcircle.svg" />
          <div>Add File</div>
        </div>
      )}
      {!localStorage.getItem("token") && (
        <div className="add">
          <div>Please buy a plan to upload your xlsx file</div>
        </div>
      )}
      {!showAddBtn && localStorage.getItem("token") && (
        <div className="add">
          <div>Please buy a plan to upload your xlsx file</div>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={"modalWindow " + classes.paper}>
            <div className="fileWrapper">
              <div className="fileCon">
                <div className="content">
                  <svg
                    className="uploadIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    height="130"
                    viewBox="0 0 24 24"
                    width="130"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
                  </svg>
                  <div className="uploadText">No file chosen, yet!</div>
                </div>
                {clear && (
                  <svg
                    onClick={handleClose}
                    id="cancelBtn"
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                )}

                {selectedFile && (
                  <div className="fileName">{selectedFile.name}</div>
                )}
                {src && <embed src={src} className="pdfFile" />}
              </div>
              <div className="customBtnCon">
                <input
                  onChange={(e) => onFileChange(e)}
                  hidden
                  id="defaultBtn"
                  type="file"
                />
                <button onClick={handleUpload} id="customBtn">
                  Choose a file
                </button>
                <button
                  disabled={showUploadBtn}
                  type="button"
                  onClick={onFileUpload}
                  id="uploadBtn"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openTwo}
        onClose={handleCloseTwo}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openTwo}>
          <div className={"modalWindow " + classes.paper}>
            <div>We Received your Data and are processing</div>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openLogin}
        onClose={handleCloseLogin}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openLogin}>
          <div className="loginMenu">
            <Login />
          </div>
        </Fade>
      </Modal>
      <Footer />
    </div>
  );
}
