import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import Joi from "joi-browser";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import firebase from "firebase/app";
import "firebase/firestore";

import SignUp from "./signUp";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ marginBottom: "20px" }}
    >
      {"Copyright © "}
      Acclaimed Labs 2021
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.dark,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [account, setAccount] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const { login } = useAuth();
  const { currentUser, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const schema = {
    email: Joi.string().email().required().label("Email"),
    password: Joi.string().min(5).required().label("Password"),
  };

  const [openSignup, setOpenSignup] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);

  const handleOpenSignup = () => {
    setOpenSignup(true);
    setShowLoginForm(false);
  };

  const handleCloseSignup = () => {
    props.handleCloseLogin();
    setOpenSignup(false);
  };

  function handleShowLoginForm() {
    setShowLoginForm(true);
    setOpenSignup(false);
  }

  function validate() {
    const result = Joi.validate(account, schema, { abortEarly: false });

    if (!result.error) return { email: "", password: "" };

    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const errors = validate();
    setErrors(errors);
    if (errors.email !== "" && errors.password !== "") return;

    try {
      setLoading(true);
      const jwt = await login(account.email, account.password);
      setCurrentUser(jwt.user.email);
      //const jwt = currentUser.refreshToken;
      if (jwt.user.email === "real-admin777@gmail.com") {
        localStorage.setItem("admin-token", jwt.user.email);
        window.location.replace("/User-files");
      } else {
        localStorage.setItem("token", jwt.user.email);
        props.handleCloseLogin();
        toast.success("Successfully Logged In", {
          position: toast.POSITION.TOP_CENTER,
          closeOnClick: true,
          autoClose: 2000,
        });
      }
      var db = firebase.firestore();
      let docRef = db.collection("users").doc(localStorage.getItem("token"));
      docRef.get().then((doc) => {
        if (doc.exists) {
          let date = new Date();
          date = date.getTime();
          if (doc.data().trialDate === "") {
            return;
          } else {
            var trialDate = doc.data().trialDate + 86400000 * 14;
            if (date >= trialDate) {
              db.collection("users")
                .doc(localStorage.getItem("token"))
                .update({
                  trial: "no",
                  trialCompleted: "yes",
                })
                .then(function (docRef) {})
                .catch(function (error) {});
            }
          }
        }
      });
    } catch {
      toast.info("Failed to login, check credentials", {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
        autoClose: 2000,
      });
    }
    setLoading(false);
    setAccount({ email: "", password: "" });
  }

  return (
    <React.Fragment>
      {showLoginForm && (
        <Container component="main" maxWidth="xs">
          <ToastContainer />
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            {errors && (
              <Grid item xs={12} sm={6}>
                <div>{errors.login}</div>
              </Grid>
            )}
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                error={errors.email ? true : false}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) =>
                  setAccount({
                    email: e.currentTarget.value,
                    password: account.password,
                  })
                }
                value={account.email}
                autoFocus
              />
              {errors && <div>{errors.email}</div>}
              <TextField
                error={errors.password ? true : false}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) =>
                  setAccount({
                    email: account.email,
                    password: e.currentTarget.value,
                  })
                }
                value={account.password}
              />
              {errors && <div>{errors.password}</div>}

              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Login
              </Button>
              <Grid container>
                {false && (
                  <Grid item xs={12} style={{ marginBottom: "10px" }}>
                    <Link
                      style={{ textDecoration: "none" }}
                      href="#"
                      variant="body2"
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                )}
                <Grid item>
                  <Link
                    onClick={handleOpenSignup}
                    style={{ textDecoration: "none" }}
                    to="#"
                    variant="body2"
                  >
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openSignup}
        onClose={handleCloseSignup}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openSignup}>
          <div className="loginMenu">
            <SignUp
              handleCloseSignup={handleCloseSignup}
              handleShowLoginForm={handleShowLoginForm}
            />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
