import React, { useEffect, useRef } from "react";
import { useAuth } from "../../contexts/authContext";
import { Link } from "react-router-dom";
import Login from "../../pages/login";
import User from "../User/User";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TweenMax, Power3, TimelineMax } from "gsap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./navBar.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function NavBar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const { currentUser } = useAuth();
  const { setCurrentUser } = useAuth();

  let navBar = useRef();
  let visibility = useRef();
  let burger = useRef();
  let bg = useRef();
  let logo = useRef();
  let navActions = useRef();
  const tl = new TimelineMax();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenLogin = async () => {
    setOpenLogin(true);
  };

  function handleCloseLogin() {
    setOpenLogin(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY;
      const navbar = document.querySelector(".nav");
      const link = document.getElementsByClassName("link");

      if (isTop !== 0) {
        if (navbar) navbar.classList.add("scrolled");
      } else {
        const v = navbar && navbar.classList;
        if (v) navbar.classList.remove("scrolled");
      }
    });

    TweenMax.to(visibility, 0, { css: { visibility: "visible" } });
    if (window.screen.width > 768) {
      tl.fromTo(bg, 1.5, { y: "-100%" }, { y: "0%", ease: Power3.easeInOut })
        .fromTo(navBar, 1, { opacity: 0, x: 30 }, { opacity: 1, x: 0 })
        .fromTo(logo, 1, { opacity: 0 }, { opacity: 1 })
        .fromTo(
          navActions,
          2,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=.5"
        );
    }
    if (window.screen.width < 768) {
      tl.fromTo(burger, 1.5, { opacity: 0 }, { opacity: 1 })
        .fromTo(logo, 1.5, { opacity: 0 }, { opacity: 1 }, "-=1.5")
        .fromTo(
          navActions,
          1,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "-=1"
        );
    }
  }, []);

  function handleUser() {
    if (localStorage.getItem("token")) {
      setCurrentUser("");
      localStorage.removeItem("token");
      window.location.reload();
    } else handleOpenLogin();
  }
  function handleBurger() {
    const burger = document.querySelector(".hamburger");
    const nav = document.querySelector(".navLinks");
    nav.classList.toggle("navActive");
    const navLinks = document.querySelectorAll(".navLinks a");
    navLinks.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = "";
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${
          index / 7 + 0.3
        }s`;
      }
    });
    burger.classList.toggle("toggle");
  }
  return (
    <div className="navContainer visibility" ref={(el) => (visibility = el)}>
      <ToastContainer />
      <div className="bg">
        {props.active !== "/about" &&
          props.active !== "/contact" &&
          props.active !== "/pricing" &&
          props.active !== "/technology" &&
          props.active !== "/upload" &&
          props.active !== "/api" && (
            <img className="bgImg" ref={(el) => (bg = el)} src="./bg2.png" />
          )}
        {(props.active == "/about" || props.active == "/contact") && (
          <img
            ref={(el) => (bg = el)}
            className="aboutbg"
            src="./aboutbg.svg"
          />
        )}
        {props.active == "/api" && (
          <img ref={(el) => (bg = el)} className="apibg" src="./api.svg" />
        )}
        {props.active == "/pricing" && (
          <img
            ref={(el) => (bg = el)}
            className="aboutbg pricingbg"
            src="./pricingbg.svg"
          />
        )}
        {props.active == "/technology" && (
          <img
            ref={(el) => (bg = el)}
            className="technologybg"
            src="./technologybg.svg"
          />
        )}
        {props.active == "/upload" && (
          <img
            ref={(el) => (bg = el)}
            className="aboutbg pricingbg"
            src="./uploadbg.svg"
          />
        )}
      </div>
      <div className="nav">
        <div className="logo" ref={(el) => (logo = el)}>
          <img src="logo.svg" />
        </div>
        <div className="navLinks" ref={(el) => (navBar = el)}>
          <Link
            className={"link " + (props.active === "/" ? "active" : "")}
            to="/"
          >
            Home
          </Link>
          <Link
            className={"link " + (props.active === "/contact" ? "active" : "")}
            to="/contact"
          >
            Contact
          </Link>
          <Link
            className={"link " + (props.active === "/pricing" ? "active" : "")}
            to="/pricing"
          >
            Pricing
          </Link>
          <Link
            className={"link " + (props.active === "/upload" ? "active" : "")}
            to="/upload"
          >
            Upload
          </Link>
          <Link
            className={
              "link " + (props.active === "/technology" ? "active" : "")
            }
            to="/technology"
          >
            technology
          </Link>
          <Link
            className={"link " + (props.active === "/api" ? "active" : "")}
            to="/api"
          >
            API
          </Link>
          <Link
            className={"link " + (props.active === "/about" ? "active" : "")}
            to="/about"
          >
            About
          </Link>
          <Link
            className={
              "link loginBtn " + (props.active === "/login" ? "active" : "")
            }
            to="#"
            onClick={handleUser}
          >
            {localStorage.getItem("token") ? "sign out" : "login"}
          </Link>
          {localStorage.getItem("token") && (
            <Link to="#" onClick={handleOpen} className="link order">
              <svg
                className={"user " + (props.active !== "/" ? "blackUser" : "")}
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
              </svg>
            </Link>
          )}
        </div>
        <div
          ref={(el) => (burger = el)}
          onClick={handleBurger}
          className="hamburger"
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
      <div ref={(el) => (navActions = el)}>
        {props.active === "/api" && (
          <div className="apiIntroduction">
            <div className="whereWorkHappens">The API Firehose. 
			<div>&nbsp;</div>
			Forecasting at Scale.</div>
            <div className="whereWorkHappensDetials">
              Auto Model and Publish Pro Forecasts. 
				<div>&nbsp;</div>
				Developer friendly API Documentation.
<div>&nbsp;</div>
<div>easily create thousands of predictions.</div>				
            </div>
          </div>
        )}
        {props.active === "/" && (
          <div>
            <div className="callToActionText">
              <div>
			 
			 
			  <div>The Premier </div>
                <span>forecasting</span>
              </div>
			  <div>SaaS solution.</div>
            
           
			  
	
         
            </div>
            <div className="callToActionBtn">
              <div>
                <Link to="/upload">free Trial no cc</Link>
              </div>
              <div>
                <Link to="/upload">Activate Free Trial</Link>
              </div>
            </div>
            <div className="LastText">
              Leverage your data with statistics. 
			  <div>&nbsp;</div>
            </div>
            <div className="LastText vido">
			<div>&nbsp;</div>
              quickly transform your organization.
			  
              <div>&nbsp;</div>
			  <div>&nbsp;</div>
              <div>auto model & publish forecasts at scale.</div>
              
              <div>&nbsp;</div>
            </div>
            {/*<div className="LastTextSec">private label API available.</div>
            <div className="LastTextSec">
              Enterprise app development support.
            </div>
            <div className="LastTextSec">
              free 30 days Api access. contact@acclaimedlabs.com
            </div>*/}
          </div>
        )}

        {/*{props.active === "/about" && <div className="aboutText">About Us</div>}
        {props.active === "/contact" && (
          <div className="aboutText">Contact Us</div>
        )}
        {props.active === "/pricing" && (
          <div className="aboutText">Pricing</div>
        )}
        {props.active === "/upload" && <div className="aboutText">Upload</div>}*/}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={{ outline: "none" }}>
            <User handleClose={handleClose} />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openLogin}
        onClose={handleCloseLogin}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openLogin}>
          <div className="loginMenu">
            <Login handleCloseLogin={handleCloseLogin} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
