import React, { useEffect } from "react";
import { NavBar } from "../../components/navBar/navBar";
import Footer from "../../components/footer/footer";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../contexts/authContext";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "../../pages/login";

import firebase from "firebase/app";
import "firebase/firestore";

import "./pricing.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Pricing(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleCloseLogin() {
    setOpenLogin(false);
  }

  const handleTrial = () => {
    var db = firebase.firestore();
    let docRef = db.collection("users").doc(localStorage.getItem("token"));
    docRef.get().then((doc) => {
      if (doc.exists) {
        if (doc.data().trial === "yes" || doc.data().trialCompleted === "yes") {
          toast.success("Free Trial Already Purchased", {
            position: toast.POSITION.TOP_CENTER,
            closeOnClick: true,
            autoClose: 2000,
          });
        } else {
          var date = new Date();
          date = date.getTime();
          db.collection("users")
            .doc(localStorage.getItem("token"))
            .update({
              trial: "yes",
              trialDate: date,
            })
            .then(function (docRef) {})
            .catch(function (error) {});
          toast.success("Free Trial Started Successfully", {
            position: toast.POSITION.TOP_CENTER,
            closeOnClick: true,
            autoClose: 2000,
          });
        }
      }
    });

    function redirectToHome() {
      handleClose();
    }
    setTimeout(redirectToHome, 1000);
  };

  const ShowError = (path) => {
    if (!localStorage.getItem("token")) {
      toast.info("First Login", {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
        autoClose: 2000,
      });
      setOpenLogin(true);
    } else if (path === "checkout") {
      props.history.push("/checkout");
    } else {
      handleOpen();
    }
  };

  return (
    <div>
      <NavBar active={props.match.path} />
      <ToastContainer />
      <div className="packages">
        <div
          className="monthly"
          style={{
            backgroundImage: "url(rectangleLeft.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <p className="packageTitle">Single Seat Monthly Plan</p>
          <p className="priceArea">
            <span className="dollarSign">$</span>
            <span className="dollarAmount">150</span>
          </p>
          <p className="usd">USD</p>
          <div className="packageFeaturesCont">
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>unlimited single upload forecasts per month</span>
            </div>
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Includes Artificial Neural Network</span>
            </div>
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Includes Professional Forecast Template</span>
            </div>
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Cross-Validation (Errors Published)</span>
            </div>
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>High Speed Modeling</span>
            </div>
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Includes Sophisticated Diagnostics</span>
            </div>
          </div>
          <div
            onClick={() => ShowError("checkout")}
            to="/pricing"
            className="cartBtn"
          >
            <div>Go to Checkout</div>
            <div>Go to Checkout</div>
          </div>
        </div>
        <div className="enterprise">
          <p className="enterpriseTitle">Enterprise Monthly API</p>
          <p className="priceArea">
            <span style={{ color: "#0240af" }} className="dollarSign">
              $
            </span>
            <span style={{ color: "#0240af" }} className="dollarAmount"></span>
          </p>
          <p
            style={{
              color: "#0240af",
              fontSize: "14px",
              width: "80%",
              textAlign: "center",
            }}
            className="usd"
          >
            free 30 day api trial contact us
          </p>
          <div className="packageFeaturesCont packageFeaturesContEnterprise">
            <div className="enterpriseFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Includes Artificial Neural Network </span>
            </div>
            <div className="enterpriseFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Includes investment grade Template</span>
            </div>
            <div className="enterpriseFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Cross-Validation (errors published)</span>
            </div>
            <div className="enterpriseFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>High Speed Modeling</span>
            </div>
            <div className="enterpriseFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Includes Sophisticated Diagnostics</span>
            </div>
            <div className="enterpriseFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Private Label – Your name and logo</span>
            </div>
            <div className="enterpriseFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Large Scale Forecasting Capabilities</span>
            </div>
          </div>
          <p className="enterpriseNum">contact@acclaimedlabs.com</p>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => props.history.replace("/contact")}
            className="cartBtn fillFormBtn"
          >
            <div>Contact us</div>
            <div>Contact us</div>
          </div>
        </div>
        <div
          className="free"
          style={{
            backgroundImage: "url(rectangleRight.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <p className="packageTitle">14-day trial plan</p>
          <p className="priceArea">
            <span className="dollarSign">$</span>
            <span className="dollarAmount">0</span>
          </p>
          <p className="usd">USD</p>
          <div className="packageFeaturesCont packageFeaturesContFree">
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>no credit card required</span>
            </div>
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>24 Forecasts free</span>
            </div>
            <div className="packageFeatures">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="18"
                  viewBox="0 0 24 24"
                  width="18"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                </svg>
              </span>
              <span>Try it now!</span>
            </div>
          </div>
          <div onClick={() => ShowError("trial")} className="cartBtn">
            <div>Start</div>
            <div>Start</div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="trialHolder">
            <div className="trialLine">
              <div className="trialSure">
                Are you sure you want to start the trial period?
              </div>
              <div className="selection">
                <div onClick={handleTrial} className="cartBtn">
                  <div>start the trial</div>
                  <div>start the trial</div>
                </div>
                <div onClick={handleClose} className="cartBtn">
                  <div>return to page</div>
                  <div>return to page</div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openLogin}
        onClose={handleCloseLogin}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openLogin}>
          <div className="loginMenu">
            <Login handleCloseLogin={handleCloseLogin} />
          </div>
        </Fade>
      </Modal>
      <Footer />
    </div>
  );
}
